import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"ma-md-0"},[_c(VCard,{attrs:{"max-width":"500","outlined":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VCheckbox,{staticClass:"mr-2",model:{value:(_vm.pingOffActive),callback:function ($$v) {_vm.pingOffActive=$$v},expression:"pingOffActive"}}),_c(VListItemContent,{staticClass:"pl-4",staticStyle:{"border-left":"1px solid rgba(0, 0, 0, .05)"}},[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Critical Mail ")]),_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_c(VIcon,{staticClass:"mr-3"},[_vm._v("mdi-router-wireless-off")]),_vm._v("Router & Computer Ping Off ")],1),_c(VListItemSubtitle,[_vm._v("Send Mail, if the Router and Computer is not pingable for "+_vm._s(_vm.pingOffThreshold)+" Minutes.")]),_c(VTextField,{staticClass:"mt-3",attrs:{"label":"Threshold","type":"number","min":"0"},model:{value:(_vm.pingOffThreshold),callback:function ($$v) {_vm.pingOffThreshold=$$v},expression:"pingOffThreshold"}}),_c(VSelect,{attrs:{"items":_vm.contacts,"chips":"","deletable-chips":"","label":"Contacts","multiple":""},model:{value:(_vm.selectedContacts),callback:function ($$v) {_vm.selectedContacts=$$v},expression:"selectedContacts"}}),_c(VTextarea,{model:{value:(_vm.mailMessage),callback:function ($$v) {_vm.mailMessage=$$v},expression:"mailMessage"}}),_c(VRow,{staticClass:"my-3 ml-0"},[_c(VBtn,{on:{"click":_vm.setPingOffNotification}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t('set'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }