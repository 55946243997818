<template>
    <v-container class="ma-md-0">
    <v-card
        max-width="500"
        outlined>
        <v-list-item three-line>
            <v-checkbox class="mr-2"
            v-model="pingOffActive"
            ></v-checkbox>

            <v-list-item-content class="pl-4" style="border-left: 1px solid rgba(0, 0, 0, .05);">
                <div class="text-overline mb-4">
                Critical Mail
                </div>
                <v-list-item-title class="text-h5 mb-1">
                <v-icon class="mr-3">mdi-router-wireless-off</v-icon>Router & Computer Ping Off
                </v-list-item-title>
                <v-list-item-subtitle>Send Mail, if the Router and Computer is not pingable for {{ pingOffThreshold }} Minutes.</v-list-item-subtitle>

                <v-text-field class="mt-3"
                    v-model="pingOffThreshold"
                    label="Threshold"
                    type="number"
                    min="0"
                ></v-text-field>

                <v-select
                    v-model="selectedContacts"
                    :items="contacts"
                    chips
                    deletable-chips
                    label="Contacts"
                    multiple
                ></v-select>

                <v-textarea
                    v-model="mailMessage">
                </v-textarea>

                <v-row class="my-3 ml-0">
                    <v-btn
                        @click="setPingOffNotification">
                        <v-icon small class="mr-1">mdi-content-save</v-icon>
                        {{ $t('set') }}
                    </v-btn>
                </v-row>
            </v-list-item-content>
        </v-list-item>
    </v-card>
    </v-container>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import { rest } from '../_helpers'


export default {
    props: {
        id: Number,
        projectContacts: {
            default() {
                return []
            },
            type: Array,
        },
        notifications: {
            default() {
                return []
            },
            type: Array,
        },
    },

    data() {
        return {
            showSpinner: true,
            pingOffThreshold: 120,
            pingOffId: null,
            pingOffActive: false,
            selectedContacts: [],
            contacts: [],
            alerts: [],
            mailMessage: '',
        }
    },

    components: {
    },

    computed: {
        ...mapState({
        }),
    },

    methods: {
        ...mapActions({
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
        }),

        setPingOffNotification() {
            const payload = {
                systemId: this.id,
                threshold: this.pingOffThreshold,
                type: 'pingOff',
                message: this.mailMessage,
                contactIds: this.selectedContacts.join(','),
                active: this.pingOffActive,
            }

            if (this.pingOffId) {
                payload.id = this.pingOffId
            }

            rest.setItem('notification', payload)
                .then(result => {
                    if (result.success) {
                        this.setSnackbar({
                            type: 'success',
                            text: `${this.$t('Record Saved')} (ID ${this.pingOffId ? this.pingOffId : 'New'})`,
                        })
                        this.showSnackbar(true)
                    }
                })
        },

        setNotifications() {
            this.alerts.forEach(alert => {
                if (alert.type === 'pingOff') {
                    this.pingOffActive = alert.active
                    this.pingOffThreshold = alert.threshold
                    this.selectedContacts = alert.contactIds.split(',').map(cI => Number.parseInt(cI, 10))
                    this.mailMessage = alert.message
                    this.pingOffId = alert.id
                }
            })
        },
    },

    created() {
        this.projectContacts.forEach(contact => {
            this.contacts.push({ text: `${contact.name} ${contact.surname}`, value: contact.id })
        })

        this.setNotifications()
    },

    watch: {
        projectContacts(val) {
            val.forEach(contact => {
                this.contacts.push({ text: `${contact.name} ${contact.surname}`, value: contact.id })
            })
        },

        notifications(val) {
            this.alerts = val
            this.setNotifications()
        },
    },
}
</script>
